.square {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(218, 199, 199);
  font-size: 90px;
  color: white;
}

.square:hover {
    cursor: pointer;
    background-color: rgb(204, 127, 127);
}
